import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/pages/Login/index'
import Complete from '@/pages/Complete/index'
import bindFix from '@/pages/bindFix/index'
import bindOver from '@/pages/bindOver/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/complete',
        name: 'complete',
        component: Complete
    },
    {
        path: '/bindFix',
        name: 'bindFix',
        component: bindFix
    },
    {
        path: '/bindOver',
        name: 'bindOver',
        component: bindOver
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
