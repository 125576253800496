<template>
    <div class="bindOver">
        <div class="overIcon">
            <div class="center">
                <van-icon name="checked" />
                <p>绑定成功</p>
            </div>
        </div>
        <div class="info">
            <h4>安装糖动APP</h4>
            <p>手机应用商城搜索“糖动”，下载并安装或扫描下方二维码进行安装</p>
            <div class="qrCode">
                <div class="android">
                    <img src="@/assets/images/a_code.jpg" alt="">
                    <p>Android</p>
                </div>
                <div class="ios">
                    <img src="@/assets/images/i_code.jpg" alt="">
                    <p>iOS</p>
                </div>
            </div>
        </div>
        <div class="footLogo">
            <img src="@/assets/images/foot-logo.png" alt="">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
    .bindOver{
        width: 100%;
        height: 100%;
        .overIcon{
            width: 100%;
            height: ~'calc(100% - 450px)';
            background-color: #16CCA6;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 80px;
            color: white;
            p{
                font-size: 16px;
                margin-top: 20px;
            }
        }
        .info{
            padding: 0px 40px;
            h4{
                font-size: 24px;
                margin-top: 30px;
                font-weight: 500;
                padding-left: 15px;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    width: 5px;
                    height: 70%;
                    top: 15%;
                    left: 0px;
                    background-color: #16CCA6;
                    border-radius: 5px;
                }
            }
            p{
                font-size: 15px;
                color: #1a1a1a;
                margin-top: 20px;
            }
            .qrCode{
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                .ios,
                .android{
                    width: 45%;
                    text-align: center;
                    img{
                        width: 100%;
                    }
                    p{
                        font-size: 14px;
                        color: #2d2d2d;
                    }
                }
            }
        }
        .footLogo{
            position: fixed;
            bottom: 20px;
            left: 0px;
            width: 100%;
            text-align: center;
            img{
                width: 245px;
                object-fit: cover;
            }
        }
    }
</style>