<template>
    <div class="Login" v-if="loading">
        <div class="logo">
            <img src="@/assets/images/head.png" alt="">
        </div>
        <div class="form">
            <div class="phone value">
                <div class="label">
                    <div class="title">
                        <img src="@/assets/images/icon2.png" alt="">
                        <p @click="isChangeCountry = true">{{ d_code }}</p>
                        <div class="prick" v-if="isChangeCountry">
                            <van-picker show-toolbar :columns="country" :default-index="dCodeIdx" @confirm="onConfirm" />
                        </div>
                    </div>
                    <div class="input">
                        <input type="tel" maxlength="11" v-model="phone" placeholder="手机号">
                    </div>
                </div>
            </div>
            <div class="code value">
                <div class="label">
                    <div class="title">
                        <img src="@/assets/images/icon1.png" alt="">
                        <div class="input">
                            <input type="tel" v-model="code" maxlength="4" placeholder="验证码">
                        </div>
                    </div>
                    <div class="send">
                        <van-button :type="type" :disabled="isSend" @click="sendCode">{{ send }}</van-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="loginBtnBox">
            <van-button class="loginBtn" @click="login">登录</van-button>
        </div>
        <div class="protocol">
            <van-checkbox shape="square" checked-color="#fff" v-model="checked">登陆即代表您同意我们的<a href="https://td.cgmcare.cn/m/protocol/user">《用户协议》</a> 及 <a href="https://td.cgmcare.cn/m/protocol/privacy">《隐私条款》</a> </van-checkbox>
        </div>
    </div>
</template>

<script>

    import { countryList, sms, check } from 'quest'
    import { setItem } from '_js/Cache'

    export default {
        data(){
            return{
                loading: false,
                type: 'info',
                isSend: false,
                isChangeCountry: false,
                country: [],
                d_code: '+86',
                dCodeIdx: 0,
                phone: '',
                code: '',
                checked: false,
                send: '发送验证码'
            }
        },
        methods:{
            getCountryList(){
                let loading = this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                });
                countryList().then( res =>{
                    if(res.code == 100000){
                        res.data.map( item =>{
                            this.country.push(item.d_code);
                        })
                        this.loading = true;
                        setTimeout(() =>{
                            loading.clear();
                        },200)
                    }
                })
            },
            onConfirm(value,index) {
                this.d_code = value;
                this.dCodeIdx = index;
                this.isChangeCountry = false;
            },
            sendCode(){
				if(this.phone <= 0 || this.phone == '' || this.phone == null){
                    this.$toast.fail('请输入手机号！');
				}else if(this.isSend){
                    this.$toast('验证码还在有效期内！');
                }else{
                    let loading = this.$toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                    });
					sms({
						d_code: this.d_code,
						mobile: this.phone,
						from: 8,
					}).then( res =>{
						if(res.code != 100000){
							this.$toast.fail(res.msg)
						}else{
                            loading.clear();
							this.isSend = true;
							this.$toast.success('验证码已发送')
							let time = 60;
							let timer = setInterval(() =>{
								time -= 1;
								this.send = time + 's后重新发送'
								if(time < 1){
									time = 60;
									this.send = '重新发送';
									clearInterval(timer);
									this.isSend = false;
								}
							},1000)
						}
					})
				}
            },
            login(){
				if(this.code != null && this.code > 0 && this.code != ''){
					if(this.phone <= 0 || this.phone == '' || this.phone == null){
						this.$toast.fail('请输入手机号！')
					}else{
						if(!this.checked){
							this.$toast.fail('请先阅读隐私协议，并点击下方同意按钮')
						}else{
                            let loading = this.$toast.loading({
                                message: '加载中...',
                                forbidClick: true,
                            });
							check({
								mobile: this.phone,
								verify_code: this.code,
                                from: 8
							}).then( res =>{
								if(res.code != 100000){
									this.$toast(res.msg)
								}else{
                                    loading.clear();
                                    setItem('token', res.data.token)
                                    setItem('userInfo', JSON.stringify(res.data))
                                    this.$toast.success('登录成功！');
                                    if(res.data.info_type == '0'){
                                        setTimeout(() =>{
                                            this.$router.push({
                                                path: '/complete'
                                            })
                                        },1000)
                                    }else{
                                        setTimeout( () =>{
                                            this.$router.push({
                                                path: '/bindFix'
                                            })
                                        },1000)
                                    }
								}
							}).catch( err =>{
                                loading.clear();
        						this.$toast.fail(err);
                            })
						}
					}
				}else{
					this.$toast.fail('请输入验证码！');
				}
            }
        },
        created(){
            this.getCountryList()
            setItem('id',this.$route.query.doctor_id);

        }
    }
</script>

<style scoped lang="less">
    .Login{
        width: 100%;
        height: 100%;
        background-image: radial-gradient(#27d8a2,#25c5ad);
        .logo{
            margin: 0 auto;
            text-align: center;
            padding-top: 100px;
            img{
                width: 175px;
                height: 120px;
            }
            p{
                font-size: 22px;
                color: #1da67f;
                margin-top: 10px;
            }
        }
        .form{
            margin-top: 30px;
            .value{
                padding: 0px 40px;
                .label{
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid hsla(0,0%,100%,.7);
                    padding-bottom: 15px;
                    .title{
                        display: flex;
                        align-items: center;
                        img{
                            width: 26px;
                            height: 26px;
                        }
                        color: white;
                        .prick{
                            position: fixed;
                            width: 100%;
                            height: 100%;
                            top: 0px;
                            left: 0px;
                            background-color: #00000064;
                            z-index: 999;
                            /deep/ .van-picker{
                                position: absolute;
                                width: 100%;
                                bottom: 0px;
                                left: 0px;
                            }
                        }
                    }
                    .input{
                        padding-left: 20px;
                        input{
                            background: none;
                            outline: none;
                            border: none;
                            color: white;
                            &::placeholder{
                                color: #f2f2f2;
                            }
                        }
                    }
                }
                &.phone{
                    .input{
                        width: ~'calc(100% - 100px)';
                        input{
                            width: 100%;
                        }
                    }
                }
                &.code{
                    margin-top: 20px;
                    .label{
                        justify-content: space-between;
                        .title{
                            width: ~'calc(100% - 180px)';
                            .input{
                                width: 100%;
                            }
                        }
                        .send{
                            /deep/ .van-button--normal{
                                background: none;
                                border: none;
                            }
                        }
                    }
                }
            }
        }

        .loginBtnBox{
            margin-top: 20px;
            padding: 0px 40px;
            /deep/ .loginBtn{
                width: 100%;
                height: 45px;
                background: #fff;
                border: none;
                color: #1da67f;
                text-align: center;
                border-radius: 50px;
            }
        }

        .protocol{
            padding: 0px 40px;
            margin-top: 20px;
            /deep/ .van-checkbox__icon--checked{
                .van-icon-success:before{
                    color: #1da67f;
                }
            }
            /deep/ .van-checkbox__label{
                font-size: 12px;
                color: white;
                a{
                    color: white;
                }
            }
        }
    }
</style>