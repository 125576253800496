export const getItem = name => {
    const data = window.localStorage.getItem(name)
    // 为什么把 JSON.parse 放到 try-catch 中？
    // 因为 data 可能不是 JSON 格式字符串
    try {
        // 尝试把 data 转为 JavaScript 对象
        return JSON.parse(data)
    } catch (err) {
        // data 不是 JSON 格式字符串，直接原样返回
        return data
    }
}

export const setItem = (name, value) => {
    // 如果 value 是对象，就把 value 转为 JSON 格式字符串再存储
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    window.localStorage.setItem(name, value)
}

export const removeItem = name => {
    window.localStorage.removeItem(name)
}

export const setCookie = (name, value, time) => {
    var Days = time; //默认将被保存 1 天
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + encodeURI(value) + ";expires=" + exp.toGMTString();
}
/***
 * @author xxxx
 * @description 获取cookie
 * @param {String} name 需要获取cookie的key
 ***/
export const getCookie = (name) => {
    var arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
    if (arr != null) {
        return decodeURI(arr[2])
    } else {
        return null
    }
}

/***
 * @author xxxx
 * @description 删除cookie
 * @param {String} name 需要删除cookie的key
 ***/
export const clearCookie = (name) => {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null) document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

/**
 * @description 时间格式化
 */

export const time = (date,format) => {
    let yFull = date.getFullYear(),
        y = date.getYear(),
        m = date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1) ) : date.getMonth() + 1,
        d = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate(),
        h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':',
        mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
        s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()),
        time = '';
    if (format == 'YY/MM/DD') {
        time = y + '/' + m + '/' + d;
    } else if (format == 'YYYY/MM/DD') {
        time = yFull + '/' + m + '/' + d;
    } else if (format == 'MM/DD') {
        time = m + '/' + d;
    } else if (format == 'MM-DD') {
        time = m + '-' + d;
    } else if (format == 'YY-MM-DD') {
        time = y + '-' + m + '-' + d;
    } else if (format == 'YYYY-MM-DD') {
        time = yFull + '-' + m + '-' + d;
    } else if( format == '年月日'){
        time = yFull + '年' + m + '月' + d + '日';
    } else if (format == '月日') {
        time = m + '月' + d + '日';
    } else if (format == 'h:m:d') {
        time = h + '-' + mm + '-' + s;
    } else if (format == 'h:m') {
        time = h + mm;
    } else {
        time = yFull + '-' + m + '-' + d;
    }
    return time
}

/**
 * @description 图片上传参数地址封装
 */
export const upLoadImage = () => {
    let md5 = require('js-md5'),
        uuid = 'uuid',
        timestamp = new Date().getTime(),
        token = getItem('token'),
        salt = 'yja2019_fcz',
        uploadImgUrl = (process.env.VUE_APP_ENV == 'test' ? 'https://test.cgmcare.cn/api' : 'https://td.cgmcare.cn/api') + '/upload/single',
        params = {
            uuid,
            timestamp,
            token,
            sign: md5(`${salt}${timestamp}${token}${uuid}`)
        },
        data = {
            url: uploadImgUrl,
            params,
        }
    return data;
}

export const deviceType = () => {
    let u = navigator.userAgent,
    type =  {
        // trident: u.indexOf('Trident') > -1, //IE内核
        // presto: u.indexOf('Presto') > -1, //opera内核
        // webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        // gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        // webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
        // weixin: u.indexOf('MicroMessenger') > -1, //是否微信 
        // qq: u.match(/\sQQ/i) == " qq" //是否QQ
    };
    return type;
}