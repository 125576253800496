<template>
    <div class="bindFix">
        <h4>绑定医生账号</h4>
        <div class="doctorInfo">
            <div class="center">
                <van-image width="10rem" height="10rem" fit="cover" :src="userInfo.avatar != '' ? userInfo.avatar : defaultHead" />
                <h5>{{ userInfo.name }}</h5>
                <p>{{ userInfo.agency_title }}</p>
            </div>
        </div>
        <div class="foot">
            <van-button type="default" @click="bindAgency">确认绑定</van-button>
            <img src="@/assets/images/foot-logo.png" alt="">
        </div>
    </div>
</template>

<script>
    import { getDoctorInfo, bindAgency } from 'quest'
    import { getItem } from '_js/Cache'
    export default {
        data(){
            return{
                userInfo: null,
                defaultHead: require('@/assets/images/notheader.png')
            }
        },
        methods:{
            getDoctorInfo(){
                let loading = this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                });
                getDoctorInfo({
                    doctor_id: getItem('id'),
                }).then(res =>{
                    if(res.code != 100000){
                        this.$toast(res.msg)
                    }else{
                        this.userInfo = res.data;
                        loading.clear();
                    }
                })
            },
            bindAgency(){
                let loading = this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                });
                bindAgency({
                    doctor_id: getItem('id')
                }).then( res =>{
                    if(res.code != 100000){
                        this.$toast(res.msg)
                    }else{
                        loading.clear();
                        this.$toast.success(res.msg);
                        this.$router.push({
                            path: '/bindOver'
                        })
                    }
                })
            }
        },
        created(){
            this.getDoctorInfo();
        }
    }
</script>

<style scoped lang="less">
    .bindFix{
        padding: 40px;
        width: 100%;
        height: 100%;
        h4{
            font-size: 28px;
            text-align: center;
        }
        .doctorInfo{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: ~'calc(100% - 10rem)';
            .center{
                text-align: center;
                /deep/ .van-image__img{
                    border-radius: 50%;
                }
                h5{
                    font-size: 22px;
                    margin-top: 20px;
                }
                p{
                    font-size: 14px;
                    margin-top: 10px;
                    color: #aaa;
                }
            }
        }
        .foot{
            text-align: center;
            .van-button{
                width: 300px;
                height: 45px;
                border-radius: 50px;
                color: white;
                background-color: #16CCA6;
                border-color: #16CCA6;
                margin-bottom: 20px;
            }
            img{
                width: 245px;
                object-fit: cover;
            }
        }        
    }
</style>