export default{
    // 登录
    login: '/system_user/login',
    // 获取国家手机号区号
    countryList: '/setting/getCountry',
    // 发送验证码
    sms: '/sms/send',
    // 验证验证码
    check: '/sms/check',
    // 保存用户信息
    updateInfo: 'user/updateinfo',
    // 获取医生信息
    getDoctorInfo: '/doctor/getDoctorInfo',
    // 确认绑定
    bindAgency: '/user/bindAgency',

}