import axios from './request.create'
import api from './Api'

// 获取国家手机号区号
export const countryList = (info) =>{
    return axios.request({
        url: api.countryList,
        isLogin: true,
        data: info,
        method: 'post',
    })
}

// 发送验证码
export const sms = (info) =>{
    return axios.request({
        url: api.sms,
        isLogin: true,
        data: info,
        method: 'post',
    })
}

// 验证验证码
export const check = (info) =>{
    return axios.request({
        url: api.check,
        isLogin: true,
        data: info,
        method: 'post',
    })
}


// 完善用户信息
export const updateInfo = (info) =>{
    return axios.request({
        url: api.updateInfo,
        isLogin: false,
        data: info,
        method: 'post',
    })
}

// 获取医生信息
export const getDoctorInfo = (info) =>{
    return axios.request({
        url: api.getDoctorInfo,
        isLogin: false,
        data: info,
        method: 'post',
    })
}

// 确认绑定

export const bindAgency = (info) =>{
    return axios.request({
        url: api.bindAgency,
        isLogin: false,
        data: info,
        method: 'post',
    })
}