<template>
    <div class="complete">
        <div class="headUpload">
            <h4>完善相关信息</h4>
            
            <van-form @submit="submit" :submit-on-enter="true">
                <van-field v-model="username" name="nickname" input-align="right" label="昵称" placeholder="请输入昵称" :rules="[{ required: true, message: '请输入昵称' }]" />

                <van-field readonly clickable name="sex" right-icon="play" input-align="right" :value="sexText" label="性别" placeholder="请选择性别" @click="sexPicker = true" :rules="[{ required: true, message: '请选择性别', trigger: 'onChange' }]">
                </van-field>
                <van-popup v-model="sexPicker" position="bottom">
                    <van-picker show-toolbar title="选择性别" :columns="['未知','男','女']" @confirm="sexConfirm" @cancel="sexPicker = false" />
                </van-popup>
                
                <van-field readonly clickable name="birthday" input-align="right" right-icon="play" :value="birthday" label="生日" placeholder="请选择出生日期" @click="birthdayPicker = true" :rules="[{ required: true, message: '请选择出生日期' }]" />
                <van-popup v-model="birthdayPicker" position="bottom">
                    <van-datetime-picker v-model="currentDate" type="date" title="选择出生日期" :min-date="minDate" :max-date="maxDate" @confirm="birthdayConfirm" @cancel="birthdayPicker = false" />
                </van-popup>
                
                <van-field readonly clickable name="weight" input-align="right" right-icon="play" :value="weight" label="体重" placeholder="请选择体重" @click="weightPicker = true" :rules="[{ required: true, message: '请选择体重' }]" />
                <van-popup v-model="weightPicker" position="bottom">
                    <van-picker show-toolbar title="选择体重" :columns="weightList" @confirm="weightConfirm" @cancel="weightPicker = false" />
                </van-popup>
                
                <van-field readonly clickable name="height" input-align="right" right-icon="play" :value="height" label="身高" placeholder="请选择身高" @click="heightPicker = true" :rules="[{ required: true, message: '请选择身高' }]" />
                <van-popup v-model="heightPicker" position="bottom">
                    <van-picker show-toolbar title="选择身高" :columns="heightList" @confirm="heightConfirm" @cancel="heightPicker = false" />
                </van-popup>
                
                <van-field readonly clickable name="suffer_type" input-align="right" right-icon="play" :value="sugar" label="患病类型" placeholder="请选择患病类型" @click="sugarPicker = true" :rules="[{ required: true, message: '请选择患病类型' }]" />
                <van-popup v-model="sugarPicker" position="bottom">
                    <van-picker show-toolbar title="患病类型" :columns="sugarList" @confirm="sugarConfirm" @cancel="sugarPicker = false" />
                </van-popup>
                
                <van-field readonly clickable name="suffer_time" input-align="right" right-icon="play" v-if="suffer_type > 0" :value="suffer_time" label="患病时间" placeholder="请选择患病时间" @click="sufferTimeBirthdayPicker = true" :rules="[{ required: true, message: '请选择患病时间' }]" />
                <van-popup v-model="sufferTimeBirthdayPicker" position="bottom">
                    <van-datetime-picker v-model="sufferTime" type="date" title="请选择患病时间" :min-date="minDate" :max-date="maxDate" @confirm="sufferTimeConfirm" @cancel="sufferTimeBirthdayPicker = false" />
                </van-popup>
                
                <van-field readonly clickable name="treatment_plan" input-align="right" right-icon="play" :value="plan" label="治疗方式" placeholder="请选择治疗方式" @click="planPicker = true" :rules="[{ required: true, message: '请选择治疗方式' }]" />
                <van-popup v-model="planPicker" position="bottom">
                    <van-picker show-toolbar title="请选择治疗方式" :columns="planList" @confirm="planConfirm" @cancel="planPicker = false" />
                </van-popup>
                
                <van-field readonly clickable name="digest_level" input-align="right" right-icon="play" :value="digestion" label="消化水平" placeholder="请选择消化水平" @click="digestionPicker = true" :rules="[{ required: true, message: '请选择消化水平' }]" />
                <van-popup v-model="digestionPicker" position="bottom">
                    <van-picker show-toolbar title="请选择消化水平" :columns="digestionList" @confirm="digestionConfirm" @cancel="digestionPicker = false" />
                </van-popup>

                <van-button round block type="info" native-type="submit">提交</van-button>
            </van-form>
        </div>
    </div>
</template>

<script>
    import { updateInfo } from 'quest';

    import { time } from '_js/Cache'

    export default {
        data(){
            return{
                username: '',
                sexText:'',
                sex: 0,
                birthday: '',
                weight: '',
                height: '',
                sugar: '',
                suffer_type: 0,
                suffer_time: '',
				planList: ['无治疗方式','饮食运动','口服药','胰岛素打针','胰岛素戴泵'],
				plan: '',
				treatment_plan: 0,
				digestionList:['正常','胃轻瘫'],
				digest_level: 0,
				digestion: '',
                sexPicker: false,
                birthdayPicker: false,
                weightPicker: false,
                heightPicker: false,
                sugarPicker: false,
                sufferTimeBirthdayPicker: false,
                planPicker: false,
                digestionPicker: false,
                minDate: new Date(1923, 1, 1),
                maxDate: new Date(),
                currentDate: new Date(),
                sufferTime: new Date(),
                heightList: [],
                weightList:[],
				sugarList: ['非糖尿病','1型糖尿病','2型糖尿病','妊娠糖尿病','其它类型糖尿病'],
            }
        },
        methods:{
            sexConfirm(value,index) {
                this.sex = index;
                this.sexText = value;
                this.sexPicker = false;
            },
            birthdayConfirm(value){
                this.birthday = time(value,'YYYY-MM-DD');
                this.birthdayPicker = false;
            },
            weightConfirm(value,index){
                this.weight = value[0] + '.' + value[1] + value[2];
                this.weightList[0].defaultIndex = index[0];
                this.weightList[1].defaultIndex = index[1];
                this.weightPicker = false;
            },
            heightConfirm(value,index){
                this.height = value[0] + value[1];
                this.heightList[0].defaultIndex = index[0]
                this.heightPicker = false;
            },
            sugarConfirm(value,index){
                this.sugar = value;
                this.suffer_type = index;
                this.sugarPicker = false;
            },
            sufferTimeConfirm(value){
                this.suffer_time = time(value,'YYYY-MM-DD');
                this.sufferTimeBirthdayPicker = false;
            },
            planConfirm(value, index){
                this.plan = value;
                this.treatment_plan = index;
                this.planPicker = false;
            },
            digestionConfirm(value, index){
                this.digestion = value;
                this.digest_level = index;
                this.digestionPicker = false;
            },
            submit(values){
                let loading = this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                });
                values.sex = this.sex,
                values.birthday = this.currentDate / 1000,
                values.suffer_type = this.suffer_type,
                values.suffer_time ? values.suffer_time = this.sufferTime / 1000 : '',
                values.suffer_type = this.suffer_type,
                values.treatment_plan = this.treatment_plan + 1,
                values.digest_level = this.digest_level + 1,
                values.weight = values.weight.replace(/[\u4e00-\u9fa5]+/g, ''),
                values.height = values.height.replace(/[\u4e00-\u9fa5]+/g, '');
                updateInfo(values).then( res =>{
                    if(res.code == 100000){
                        loading.clear();
                        this.$toast.success('保存成功！');
                        setTimeout( () =>{
                            this.$router.push({
                                path: '/bindFix'
                            })
                        },1000)
                    }
                })
            }
        },
        created(){
			let arr1 = [],
				arr2 = [],
				arr3 = [];
			for(let i = 0 ; i <= 300; i++){
				if(i >= 50){
					arr1.push(i);
				}
				if(i > 9 && i <= 200){
					arr2.push(i);
				}
				if(i < 10){
					arr3.push(i);
				}
			}
			this.heightList = [
                {
                    values: arr1,
                    defaultIndex: 120,
                },
                {
                    values: ['厘米'],
                    defaultIndex: 0,
                }
            ];
			this.weightList = [
                {
                    values: arr2,
                    defaultIndex: 50,
                },
                {
                    values: arr3,
                    defaultIndex: 0,
                },
                {
                    values: ['千克'],
                    defaultIndex: 0,
                },
            ];
        }
    }
</script>

<style scoped lang="less">
    .complete{
        width: 100%;
        .headUpload{
            width: 100%;
            padding: 0px 40px;
            padding-bottom: 20px;
            position: relative;
            h4{
                font-size: 18px;
                color: #AAA;
                text-align: center;
                margin-top: 20px;
            }
            /deep/ .van-form{
                margin-top: 20px;
                .van-field{
                    border: 1px solid #f0f0f0;
                    border-radius: 50px;
                    margin-top: 25px;
                    .van-field__label{
                        span{
                            color: #AAA;
                        }
                    }
                    .van-icon{
                        font-size: 12px;
                        border-radius: 10px;
                        transform: rotate(90deg);
                    }
                    &::after{
                        content: unset;
                    }
                }
                .van-field__error-message{
                    display: none !important;
                }
                .van-button{
                    max-width: 425px;
                    width: 100%;
                    margin-top: 40px;
                    background-color: #16CCA6;
                    border-color: #16CCA6;
                }
            }
        }
    }
</style>